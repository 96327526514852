import React from "react"
import "./SecurityList.scss"

function Securitylist(props) {
  return (
    <div className="security-component row justify-content-center">
      {props.data.map(securitylist => {
        return (
          <div className="col-md-6" key={securitylist.title}>
            <div className="single-service-2 service-6 d-flex mt-30">
              <div className="security--service-icon">
                <i className={"flaticon-" + securitylist.flaticon}></i>
              </div>
              <div className="service-content media-body">
                <h4 className="title">
                  {securitylist.title}
                </h4>
                {/* <p className="text">{securitylist.text}</p> */}
                <p className="text" dangerouslySetInnerHTML={{
                                __html: securitylist.text
                              }}></p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Securitylist
