import React from "react"
import { graphql } from "gatsby"
import Link from "gatsby-link"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import Header from "../components/Header"
import PageWrapper from "../PageWrapper"
import heroSvg from "../img/undraw/undraw_security_o890.svg"
import PAGEDATA from "../../content/security.json"
import SecurityList from "../components/SecurityList"

function Hero({ bgImage }){
  return (
      <>
          <div className="header-hero bg_cover d-lg-flex align-items-center">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-5">
                          <div className="header-hero-content">
                              <h1 className="hero-title"><b>Data Privacy</b></h1>
                              <p className="hero-subtitle mt-3"> lexiQA is committed to the security and confidentiality of your data. Access to your translation data is private and exclusive to your account.</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="header-hero-image d-flex align-items-center">
                  <div className="image">
                      <img src={bgImage} alt="Hero" />
                  </div>
              </div>
          </div>
      </>

  );

}

//                     { data, location }
const SecurityIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Header component={<Hero bgImage={heroSvg} />} title={siteTitle} />
      <SEO title="Security" />

      {/* <!--====== SECOND SECTION START======--> */}
      <section className="services-area-2 bg-light pt-115 pb-115">
        <div className="container">
          <SecurityList data={PAGEDATA.securitylist} />
          <div className="row justify-content-center pt-70">
            <div className="col-md-9">
              <p>
                All lexiQA operations and privacy policy are also compliant with the
                UK Data Protection Act of 2018 and the GDPR. For more details
                please see our <Link className="security-link lxq-link" to="/privacy">
                  Privacy Policy.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!--====== SECOND SECTION START======--> */}


      <Footer />
    </PageWrapper>
  )
}

export default SecurityIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
